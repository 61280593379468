import { PLAYER_TYPE } from '../config'

let audioContext

/**
 * 通过sessionId获取对应的playerId
 * @param sessionId
 * @returns {number}
 */
export function getPlayerIdFromSessionId(sessionId) {
    sessionId = parseInt(sessionId)
    // sessionId为userId + PLAYER_TYPE，userId最后一位为0，直接求余得到PLAYER_TYPE
    const playerType = sessionId % 10
    const userId = sessionId - playerType
    if (
        playerType === PLAYER_TYPE.ASSIST_CAMERA ||
        playerType === PLAYER_TYPE.ASSIST_SCREEN_SHARE ||
        playerType === PLAYER_TYPE.ASSIST_MEDIA_FILE
    ) {
        // 辅摄Id为userId + 1
        return userId + 1
    }
    return userId
}

/**
 * 通过userId与流类型生成sessionId
 * @param userId
 * @param streamType
 * @returns {number}
 */
export function generateSessionId(userId, streamType) {
    return parseInt(userId) + parseInt(streamType)
}

/**
 * 通过sessionId获取流类型
 * @param sessionId
 * @returns {number}
 */
export function getStreamType(sessionId) {
    sessionId = parseInt(sessionId)
    return sessionId % 10
}

/**
 * 通过sessionId获取流类型
 * @param sessionId
 * @returns {number}
 */
export function getUserId(sessionId) {
    sessionId = parseInt(sessionId)
    // sessionId为userId + PLAYER_TYPE，userId最后一位为0，直接求余得到PLAYER_TYPE
    const playerType = sessionId % 10
    const userId = sessionId - playerType
    return userId + ''
}

export function getAudioContext() {
    if (audioContext) {
        return audioContext
    }

    let AudioContext = window.AudioContext || window.webkitAudioContext
    audioContext = new AudioContext()
    return audioContext
}

export function createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
    const processor = audioContext.createScriptProcessor(512)

    processor.onaudioprocess = (event) => {
        const buf = event.inputBuffer.getChannelData(0)
        const bufLength = buf.length
        let sum = 0
        let x

        // Do a root-mean-square on the samples: sum up the squares...
        for (let i = 0; i < bufLength; i++) {
            x = buf[i]
            if (Math.abs(x) >= processor.clipLevel) {
                processor.clipping = true
                processor.lastClip = window.performance.now()
            }
            sum += x * x
        }

        // ... then take the square root of the sum.
        const rms = Math.sqrt(sum / bufLength)

        // Now smooth this out with the averaging factor applied
        // to the previous sample - take the max here because we
        // want "fast attack, slow release."
        processor.volume = Math.max(rms, processor.volume * processor.averaging)
    }

    processor.clipping = false
    processor.lastClip = 0
    processor.volume = 0
    processor.clipLevel = clipLevel || 0.98
    processor.averaging = averaging || 0.95
    processor.clipLag = clipLag || 750

    // this will have no effect, since we don't copy the input to the output,
    // but works around a current Chrome bug.
    processor.connect(audioContext.destination)

    processor.checkClipping = () => {
        if (!processor.clipping) return false
        if (processor.lastClip + processor.clipLag < window.performance.now()) processor.clipping = false
        return processor.clipping
    }

    processor.shutdown = () => {
        processor.disconnect()
        processor.onaudioprocess = null
        // audioContext.close();
    }

    return processor
}
