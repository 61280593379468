/**
 * @file session的管理适配,为了和连刻的保持接口统一。
 * @auth yikai
 * @auth dujianhao
 * @auth yanlingling
 */
import Events from 'events'
import { PLAYER_TYPE, DEFAULT_MEDIA_BLOCK_CONFIG } from '../config'
import mainSessionManager from './mainSessionManager'
import { getPlayerIdFromSessionId, generateSessionId, getStreamType } from '../util/util'
import { getLogger } from '../util/log'
import MediaBlockManager from '../util/MediaBlockManager'

const SUBSCRIBER_STATUS_PUBLISHED = 0
const SUBSCRIBER_STATUS_RECONNECTING = 1
const SUBSCRIBER_STATUS_RECONNECT_SUCCESS = 2
const SUBSCRIBER_STATUS_RECONNECT_FAIL = 3
const SUBSCRIBER_STATUS_LOST = 4
const SUBSCRIBER_STATUS_USER_UNSUBSCRIBING = 5
const SUBSCRIBER_STATUS_USER_UNSUBSCRIBED = 6

const logger = getLogger('agora session')

class AgoraSessionManager extends Events {
    constructor() {
        super()

        this.playerId = null
        this.streamType = null

        this._sessionId = null

        // 用于订阅的session
        this._subscribeSession = null
        this._subscribeConnected = false
        this._subscriberStore = {}

        // 用于发布的session
        this._publishSession = null
        this._publishConnected = false
        this._publisherStore = {}

        this._streamLog = {}

        this._stateLog = {}
    }

    createSession({
        mode = 'live',
        codec = 'h264',
        appId,
        token,
        channel,
        playerId,
        userId,
        streamType,
        webrtcInfo,
        lags
    } = {}) {
        this.playerId = playerId
        this.streamType = streamType
        this.params = {
            mode,
            appId,
            token,
            codec,
            channel,
            playerId,
            userId,
            streamType,
            webrtcInfo,
            lags: Object.assign(DEFAULT_MEDIA_BLOCK_CONFIG, lags)
        }

        logger.info('create session: ', this.params)

        this._sessionId = generateSessionId(userId, streamType)
        this.mediaBlockManager = new MediaBlockManager(this.params.lags)
        const res = this._createMainSession()

        if (streamType != PLAYER_TYPE.MAIN_CAMERA) {
            // 只建立本地session,没有加入channel
            this._createAssistPublishSession()
        }

        return res
    }

    /**
     * 是否连接状态
     * @returns {boolean}
     */
    isConnected() {
        return this._subscribeConnected
    }

    disconnect() {
        if (
            (!this._publishSession || !this._publishConnected) &&
            (!this._subscribeSession || !this._subscribeConnected)
        ) {
            return Promise.resolve()
        }

        return new Promise(async (resolve, reject) => {
            try {
                logger.info('try to disconnect session: ', this._sessionId)

                if (Object.keys(this._publisherStore).length !== 0) {
                    await this.publishAVClose({
                        playerId: this._publisherStore.playerId,
                        stream: this._publisherStore.stream
                    })
                    await this._destroySession()
                } else {
                    await this._destroySession()
                }

                this._publishSession = null
                this._subscribeSession = null
                this._streamLog = {}

                resolve({
                    playerId: this.playerId
                })
            } catch (e) {
                logger.error('disconnect fail', e, this._sessionId)
                reject(e)
            }
        })
    }

    publishAV({ playerId, stream } = {}) {
        return new Promise((resolve, reject) => {
            if (this._publisherStore.stream) {
                reject()
                return
            }
            if (stream) {
                if (this._publishSession && this._publishConnected) {
                    this._doPublish({ playerId, stream })
                    resolve()
                } else {
                    this._publishSessionJoin().then(
                        () => {
                            this._doPublish({ playerId, stream })
                            resolve()
                        },
                        (error) => reject(error)
                    )
                }
            } else {
                resolve()
            }
        })
    }

    republishAV({ playerId, stream } = {}) {
        if (stream && this.playerId === playerId && this._publishSession && this._publishConnected) {
            if (this._streamLog[playerId]) {
                clearInterval(this._streamLog[playerId].timer)
                clearInterval(this._streamLog[playerId].blockTimer)
                delete this._streamLog[playerId]
            }

            logger.info('unpublish local stream: ', playerId)
            this._publishSession.unpublish(this._publisherStore.stream, (error) =>
                logger.error('unpublish local stream fail: ', error)
            )

            setTimeout(() => this._doPublish({ playerId, stream }), 1000)
        }
    }

    publishAVClose({ playerId, stream }) {
        return new Promise((resolve, reject) => {
            if (!this._publishConnected) {
                resolve()
                return
            }

            if (this.playerId === playerId) {
                logger.info('begin unpublish: ', this._sessionId)
                if (this._streamLog[playerId]) {
                    clearInterval(this._streamLog[playerId].timer)
                    clearInterval(this._streamLog[playerId].blockTimer)
                    delete this._streamLog[playerId]
                }
                if (this._publishSession) {
                    if (stream) {
                        this._publishSession.unpublish(stream, (err) => {
                            logger.info('unpublish local stream fail: ', err)
                        })
                        this.emit('removePublisher', null, { playerId, stream })
                        this.emit('cleanStream', null, { playerId, stream })
                        // unpublish结束再退出
                        setTimeout(() => {
                            this._destroyPublishSession().then(() => {
                                this.emit('unpublished', null, { playerId })
                            })
                        }, 500)
                        resolve(playerId)
                    } else {
                        logger.error('stream not exist')
                        this._destroyPublishSession().then(() => {
                            this.emit('unpublishd', null, { playerId })
                        })
                        reject('stream not exist')
                    }
                } else {
                    reject('session not connect')
                }
            }

            this._publisherStore = {}
        })
    }

    attachVideo(player) {
        if (this._publishConnected && player.__avStream) {
            logger.info('attach video: ', player.id)
            player.__avStream.unmuteVideo()
            return true
        }
        return false
    }

    detachVideo(player) {
        if (this._publishConnected && player.__avStream) {
            logger.info('detach video: ', player.id)
            player.__avStream.muteVideo()
            return true
        }
        return false
    }

    attachAudio(player) {
        if (this._publishConnected && player.__avStream) {
            logger.info('attach audio: ', player.id)
            player.__avStream.unmuteAudio()
            return true
        }
        return false
    }

    detachAudio(player) {
        if (this._publishConnected && player.__avStream) {
            logger.info('detach audio: ', player.id)
            player.__avStream.muteAudio()
            return true
        }
        return false
    }

    subscribe({ playerId, stream }) {
        if (stream) {
            this._subscriberStore[playerId] = {
                playerId,
                stream,
                reconnectTimer: null,
                unsubscribeTimer: null,
                reconnectCount: 0,
                lowMOSCount: 0,
                mosWarningIssued: false,
                status: SUBSCRIBER_STATUS_PUBLISHED
            }
            logger.info(`${this._sessionId} try to subscribe stream ${stream.getId()}`)
            return new Promise((resolve, reject) => {
                this._subscribeSession.subscribe(stream, (e) => {
                    logger.error('subscribe failed: ', e, playerId)
                    this.emit('subscribeError', null, { playerId })
                    reject()
                })
                resolve()
            })
        } else {
            logger.error(`subscribe error: stream is undefined`)
        }
    }

    resubscribe({ playerId, stream }) {
        if (!this._subscribeConnected) {
            logger.info('session disconnect, give up subscriber reconnect: ', playerId)
            clearTimeout(this._subscriberStore[playerId].reconnectTimer)
            this._subscriberStore[playerId].reconnectCount = 0
            this._subscriberStore[playerId].status = SUBSCRIBER_STATUS_LOST
            return
        }

        if (this._subscriberStore[playerId]) {
            if (
                [
                    SUBSCRIBER_STATUS_RECONNECTING,
                    SUBSCRIBER_STATUS_USER_UNSUBSCRIBING,
                    SUBSCRIBER_STATUS_USER_UNSUBSCRIBED
                ].includes(this._subscriberStore[playerId].status)
            ) {
                return
            }
            logger.info('session manager resubscribe called: ' + playerId)
            this._subscriberStore[playerId].reconnectCount += 1
            logger.info('subscriber reconnect time: ' + this._subscriberStore[playerId].reconnectCount)
            this._subscriberStore[playerId].status = SUBSCRIBER_STATUS_RECONNECTING
            clearTimeout(this._subscriberStore[playerId].reconnectTimer)
            this._doResubscribe({ playerId, stream })
        } else {
            logger.info('give up resubscribe because no subscriber')
        }
    }

    unsubscribe({ playerId, stream }) {
        if (this._subscriberStore[playerId]) {
            logger.info('unsubscribe called for: ', playerId)
            this._subscribeSession.unsubscribe(stream, (err) => logger.error(err))
            this._subscriberStore[playerId].status = SUBSCRIBER_STATUS_USER_UNSUBSCRIBED
        }
    }

    // 不要删除这个方法，外层会调用
    subMute(options, playerId, stream) {}

    // 开启双流模式
    enableDualStream(options) {
        if (!this._publishSession) {
            return
        }
        if (options) {
            this._publishSession.setLowStreamParameter({
                width: options.width,
                height: options.height,
                bitrate: options.bitrate,
                framerate: options.framerate
            })
        }

        this._publishSession.enableDualStream(
            () => {
                logger.info('Enable dual stream success!')
            },
            (err) => {
                logger.error(err)
            }
        )
    }

    // 关闭双流模式
    disableDualStream() {
        this._publishSession &&
            this._publishSession.disableDualStream(
                () => {
                    logger.info('Disable dual stream success!')
                },
                (err) => {
                    logger.error(err)
                }
            )
    }

    // 切换大小流
    switchStream({ highOrLow, stream }) {
        this._subscribeSession && this._subscribeSession.setRemoteVideoStreamType(stream, highOrLow)
    }

    _createMainSession() {
        logger.info('create main session: ', this.playerId, this.streamType)

        const me = this
        const params = me.params
        const streamType = me.streamType
        const mainSession = mainSessionManager.create(params)

        mainSession.then((mainInstance) => {
            logger.info('main session create success: ', this.playerId, this.streamType)

            const session = mainInstance.session
            this._subscribeSession = session
            this._subscribeConnected = true

            // 主摄像头推拉流用一个session
            if (params.streamType == PLAYER_TYPE.MAIN_CAMERA) {
                this._publishSession = session
                this._publishConnected = true
                mainInstance.on('published', (event, data) => {
                    logger.info('main instance receive published')
                    me.emit('published', null, data)
                })
            }
            this._stateInterval && clearInterval(this._stateInterval)
            this._stateInterval = setInterval(() => this.startGetState(), 2000)

            mainInstance
                .on('sessionError', () => {
                    me.emit('renewWebrtcInfo')
                })
                .on('renewWebrtcInfo', () => {
                    me.emit('renewWebrtcInfo')
                })
                .on('addPublisher', (evt, data) => {
                    if (data.streamType === streamType && data.playerId != me.playerId) {
                        logger.info('type session emit addPublisher: ', data)
                        me.emit('addPublisher', null, data)
                    }
                })
                .on('removePublisher', (evt, data) => {
                    if (data.streamType === streamType && data.playerId != me.playerId) {
                        me.emit('removePublisher', null, data)
                    }
                })
                .on('addStream', (evt, data) => {
                    if (data.streamType === streamType && data.playerId != me.playerId) {
                        me.emit('addStream', null, data)
                    }
                })
                .on('cleanStream', (evt, data) => {
                    if (data.streamType === streamType && data.playerId != me.playerId) {
                        me.emit('cleanStream', null, data)
                    }
                })
                .on('downlinkStats', (evt, data) => {
                    const dataStreamType = getStreamType(data.sessionId)
                    data.playerId = getPlayerIdFromSessionId(data.sessionId)
                    if (dataStreamType === streamType) {
                        me.emit('downlinkStats', null, data)
                    }
                })
                .on('flency-report', (evt, data) => {
                    const dataStreamType = getStreamType(data.sessionId)
                    data.userId = getPlayerIdFromSessionId(data.sessionId)
                    if (dataStreamType === streamType) {
                        me.emit('flency-report', null, data)
                    }
                })
        })

        return mainSession
    }

    _createAssistPublishSession() {
        console.group('create publish session')

        const params = this.params
        const sessionId = this._sessionId

        // Create Session Timeout
        const sessionTimeout = new Promise((resolve) => {
            let wait = setTimeout(() => {
                clearTimeout(wait)
                resolve(-1)
            }, 15 * 1000)
        })

        const sessionConnection = new Promise((resolve) => {
            logger.info('try to publish session: ', this._publishSession)

            if (this._publishSession) {
                logger.info('publish session existed: ', this._publishSession)
                resolve(this._publishSession)
            } else {
                logger.info('create publish session: ', this._publishSession)
                this._publishSession = global.AgoraRTC.createClient({
                    mode: params.mode,
                    codec: params.codec
                })
                this._publishSession.init(
                    params.appId,
                    () => {
                        let session = this._publishSession
                        session.on('error', (err) => {
                            logger.info('Session error throwed', err.reason)
                            if (err.reason === 'DYNAMIC_KEY_TIMEOUT') {
                                this.emit('renewWebrtcInfo')
                            }
                        })

                        session.on('exception', (evt) => {
                            logger.info('session exception throwed', evt)
                            this.emit('publishException', null, evt)
                        })

                        session.on('stream-published', (evt) => {
                            const stream = evt.stream
                            if (stream) {
                                const streamId = stream.getId()
                                // 只发出自己对应流的消息
                                if (sessionId === streamId) {
                                    logger.info(`new stream published: ${streamId}`)
                                    this.emit('published', null, {
                                        stream,
                                        sessionId,
                                        playerId: '' + getPlayerIdFromSessionId(sessionId)
                                    })
                                }
                            } else {
                                logger.error('stream-published event error: this is no stream')
                            }
                        })

                        resolve(session)
                    },
                    (err) => {
                        logger.error('AgoraRTC client init fail', err)
                    }
                )
            }
        })

        console.groupEnd('create publish session')

        return Promise.race([sessionTimeout, sessionConnection])
    }

    _destroySession() {
        logger.info('destroy session: ', this.playerId, this.streamType)

        Object.keys(this._streamLog).forEach((key) => {
            clearInterval(this._streamLog[key].timer)
            clearInterval(this._streamLog[key].blockTimer)
        })

        let finished = false

        const disconnectTimeout = new Promise((resolve) => {
            setTimeout(() => {
                if (!finished) {
                    logger.info('leave channel timeout', this.playerId, this.streamType)
                    finished = true
                    resolve(-1)
                }
            }, 5 * 1000)
        })

        let publishSessionDisconnect = this._publishConnected ? this._destroyPublishSession() : Promise.resolve()
        let subscribeSessionDisconnect = this._subscribeConnected ? this._destroySubscribeSession() : Promise.resolve()

        const sessionDisconnect = Promise.all([publishSessionDisconnect, subscribeSessionDisconnect]).then(
            () => (finished = true)
        )

        return Promise.race([disconnectTimeout, sessionDisconnect])
    }

    /**
     * 销毁订阅session
     * @returns {Promise}
     */
    _destroySubscribeSession() {
        return new Promise((resolve, reject) => {
            if (this._subscribeConnected) {
                logger.info('destroy subscribe session: ', this._sessionId)

                mainSessionManager.removeStreamType(this.streamType)
                Object.keys(this._subscriberStore).forEach((key) => {
                    this.emit('cleanStream', null, {
                        playerId: this._subscriberStore[key].playerId,
                        streamType: this.streamType
                    })
                })

                this._subscriberStore = {}
                this._subscribeConnected = false

                // 主session里面一个streamType都没有了,退出
                if (mainSessionManager.getStreamTypes().length === 0) {
                    mainSessionManager.destroy().then(
                        () => {
                            resolve()
                        },
                        (error) => {
                            reject(error)
                        }
                    )
                } else {
                    resolve()
                }
            } else {
                resolve()
            }
        })
    }

    /**
     * 销毁发布session
     * @returns {Promise}
     */
    _destroyPublishSession() {
        return new Promise((resolve, reject) => {
            if (this._publishConnected) {
                logger.info('destroy publish session: ', this._sessionId)
                if (this.streamType === PLAYER_TYPE.MAIN_CAMERA) {
                    logger.info('main publish session need not destroy')
                    resolve()
                    return
                }
                this._publishSession.leave(
                    () => {
                        this._publishConnected = false
                        logger.info('leave session successfully: ', this._sessionId)
                        resolve()
                    },
                    (err) => {
                        logger.error('leave session failed: ', err, this._sessionId)
                        reject('leave session failed')
                    }
                )
            } else {
                reject('session is not connect')
            }
        })
    }

    _doPublish({ playerId, stream } = {}) {
        this.emit('addStream', null, { playerId, stream })
        this._publisherStore = {
            playerId,
            stream
        }
        this.playerId = playerId

        //避免某些情况下timer未销毁
        if (this._streamLog[playerId]) {
            clearInterval(this._streamLog[playerId].timer)
            clearInterval(this._streamLog[playerId].blockTimer)
        }
        this._streamLog[playerId] = {
            log: {
                videoSendBytes: 0,
                audioSendBytes: 0
            },
            mediaBlock: this.mediaBlockManager.createMediaBlockInstance(playerId, true, true, this.params.lags), //上行 有丢包率
            timer: setInterval(() => {
                stream.getStats((log) => {
                    let prevVideoSendBytes = this._streamLog[playerId].log.videoSendBytes
                    let prevAudioSendBytes = this._streamLog[playerId].log.audioSendBytes
                    let uplinkVideoLossRate = (log.videoSendPacketsLost / log.videoSendPackets).toFixed(2)
                    let uplinkAudioLossRate = (log.audioSendPacketsLost / log.audioSendPackets).toFixed(2)
                    let uplinkVideoBandwidth = (8 * (log.videoSendBytes - prevVideoSendBytes)) / 1000
                    let uplinkAudioBandwidth = (8 * (log.audioSendBytes - prevAudioSendBytes)) / 1000
                    let rtt = 0
                    this.emit('uplinkStats', null, {
                        playerId,
                        uplinkVideoLossRate: uplinkVideoLossRate,
                        uplinkAudioLossRate: uplinkAudioLossRate,
                        uplinkVideoBandwidth: uplinkVideoBandwidth,
                        uplinkAudioBandwidth: uplinkAudioBandwidth,
                        rtt: rtt
                    })
                    this._streamLog[playerId].log = log
                })
            }, 1000),
            blockTimer: setInterval(() => {
                let state = this._streamLog[playerId]
                let mediaBlock = state && state.mediaBlock
                let log = (state && state.log) || {}
                let _state = {
                    audioLossRate: (log.audioSendPacketsLost / log.audioSendPackets).toFixed(2),
                    videoLossRate: (log.videoSendPacketsLost / log.videoSendPackets).toFixed(2),
                    fps: log.videoSendFrameRate
                }
                if (mediaBlock) {
                    let isBlock = mediaBlock.isBlock(_state)
                    window.enableBJYDebugLog &&
                        logger.debug(
                            'agora-uplink-state:',
                            playerId,
                            ', current:',
                            _state,
                            ', winAvg:',
                            mediaBlock.getValues()
                        )
                    if (isBlock) {
                        window.enableBJYDebugLog &&
                            logger.debug('agora-uplink-flency-report:', playerId, mediaBlock.getValues())
                        this.emit('flency-report', null, { userId: playerId })
                    }
                }
            }, 2000)
        }

        logger.info('publish stream: ', this._publishSession, stream)

        this._publishSession.publish(stream, (err) => {
            logger.error('publish local stream error: ', err)
            if (this._streamLog[playerId]) {
                clearInterval(this._streamLog[playerId].timer)
                clearInterval(this._streamLog[playerId].blockTimer)
                delete this._streamLog[playerId]
            }
            this.emit('publishError', null, { playerId })
        })
        this._handlePublishTimeout()
    }

    _handlePublishTimeout() {
        this._publishTimeoutTimer && clearTimeout(this._publishTimeoutTimer)
        this._publishTimeoutTimer = setTimeout(() => {
            this.emit('republish', null, {
                playerId: this.playerId
            })
            this.off('published', handler)
            this._publishTimeoutTimer = null
        }, 10 * 1000)
        const handler = (event, data) => {
            if (data.sessionId === this._sessionId) {
                clearTimeout(this._publishTimeoutTimer)
                this._publishTimeoutTimer = null
                this.off('published', handler)
            }
        }
        this.on('published', handler)
    }

    _publishSessionJoin() {
        const me = this
        const params = this.params
        const playerId = me.playerId

        logger.info('publishSessionJoin: ', params)

        return new Promise((resolve, reject) => {
            this._publishSession.join(
                params.token,
                params.channel,
                me._sessionId,
                () => {
                    logger.info('join channel succeed')
                    me._publishConnected = true
                    resolve()
                },
                (err) => {
                    this.emit('sessionError', null, { playerId })
                    logger.error('join channel fail: ', err)
                    reject(err)
                }
            )
        })
    }

    _doResubscribe({ playerId, stream }) {
        logger.info('resubscribing ' + playerId)
        if (this._subscribeConnected) {
            this._subscribeSession.subscribe(stream, (err) => {
                logger.error('resubscribe fail: ', playerId, err)
                clearTimeout(this._subscriberStore[playerId].reconnectTimer)
                this._subscriberStore[playerId].status = SUBSCRIBER_STATUS_RECONNECT_FAIL
                this._subscriberStore[playerId].reconnectTimer = setTimeout(() => {
                    this.resubscribe({ playerId, stream })
                }, 2000)
            })
            logger.info('resubscribe success: ', playerId)
            clearTimeout(this._subscriberStore[playerId].reconnectTimer)
            this._subscriberStore[playerId].status = SUBSCRIBER_STATUS_RECONNECT_SUCCESS
        } else {
            logger.info('session disconnected, give up retrying')
        }
    }

    startGetState() {
        // 查询声网卡顿数据
        if (this._publishSession && this._publishConnected) {
            this._publishSession.getLocalVideoStats((localVideoStats) => {
                for (let uid in localVideoStats) {
                    let stremType = getStreamType(uid)
                    if (stremType === this.streamType) {
                        let state = localVideoStats[uid]
                        window.enableBJYDebugLog &&
                            logger.debug(
                                'getLocalVideoState, streamType:',
                                this.streamType,
                                ', player:',
                                getPlayerIdFromSessionId(uid),
                                {
                                    blockTime: state.TotalFreezeTime,
                                    captureFps: state.CaptureFrameRate,
                                    sendFps: state.SendFrameRate
                                }
                            )
                        let _state = this._stateLog[uid]
                        if (_state && _state.blockTime < state.TotalFreezeTime) {
                            window.enableBJYDebugLog &&
                                logger.debug(
                                    'reference-flency-report, block time:',
                                    state.TotalFreezeTime - _state.blockTime,
                                    'player:',
                                    getPlayerIdFromSessionId(uid)
                                )
                            this.emit('flency-report', null, {
                                userId: getPlayerIdFromSessionId(uid),
                                isReference: true
                            })
                        }
                        this._stateLog[uid] = { blockTime: state.TotalFreezeTime }
                    }
                }
            })
        }
        if (this._subscribeSession && this._subscribeConnected) {
            this._subscribeSession.getRemoteVideoStats((remoteVideoStatsMap) => {
                for (let uid in remoteVideoStatsMap) {
                    let stremType = getStreamType(uid)
                    if (stremType === this.streamType) {
                        let state = remoteVideoStatsMap[uid]
                        window.enableBJYDebugLog &&
                            logger.debug(
                                'remoteVideoStatsMap',
                                this.streamType,
                                ', player:',
                                getPlayerIdFromSessionId(uid),
                                {
                                    blockTime: state.TotalFreezeTime,
                                    fps: state.RenderFrameRate
                                }
                            )
                        let _state = this._stateLog[uid]
                        if (_state && _state.blockTime < state.TotalFreezeTime) {
                            window.enableBJYDebugLog &&
                                logger.debug(
                                    'reference-flency-report, block time:',
                                    state.TotalFreezeTime - _state.blockTime,
                                    'player:',
                                    getPlayerIdFromSessionId(uid)
                                )
                            this.emit('flency-report', null, {
                                userId: getPlayerIdFromSessionId(uid),
                                isReference: true
                            })
                        }
                        this._stateLog[uid] = { blockTime: state.TotalFreezeTime }
                    }
                }
            })
        }
    }
}

export default AgoraSessionManager
