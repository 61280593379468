/**
 * wrap MediaStream
 * MediaStream : https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API
 */
class StreamWrapper {
    constructor(mediaStream) {
        //public

        // this.videoTrack = null;
        // this.audioTrack = null;
        this.mediaStream = null

        if (mediaStream) {
            this.setter(mediaStream)
        }

        this.onaudioend = () => {
            RTCat.log.D('stream audio over')
        }

        this.onaudiostarted = () => {
            RTCat.log.D('stream audio started')
        }

        this.onaudiomute = () => {
            RTCat.log.D('stream audio mute')
        }

        this.onaudiounmute = () => {
            RTCat.log.D('stream audio unmute')
        }

        this.onvideoend = () => {
            RTCat.log.D('stream video over')
        }

        this.onvideostarted = () => {
            RTCat.log.D('stream video started')
        }

        this.onvideomute = () => {
            RTCat.log.D('stream video mute')
        }

        this.onvideounmute = () => {
            RTCat.log.D('stream video unmute')
        }
        this.onerror = (error) => {
            RTCat.log.E(error)
        }
    }

    setter(mediaStream) {
        this.mediaStream = mediaStream

        if (this.videoTrack != null) {
            this.videoTrack.onended = () => {
                this.onvideoend()
            }
            this.videoTrack.onstarted = () => {
                this.onvideostarted()
            }
            this.videoTrack.onmute = () => {
                this.onvideomute()
            }
            this.videoTrack.onunmute = () => {
                this.onvideounmute()
            }
        }

        if (this.audioTrack != null) {
            this.audioTrack.onended = () => {
                this.onaudioend()
            }
            this.audioTrack.onstarted = () => {
                this.onaudiostarted()
            }
            this.audioTrack.onmute = () => {
                this.onaudiomute()
            }
            this.audioTrack.onunmute = () => {
                this.onaudiounmute()
            }
        }
    }

    hasVideo() {
        return this.mediaStream && this.mediaStream.getVideoTracks() && this.mediaStream.getVideoTracks().length > 0
    }

    hasAudio() {
        return this.mediaStream && this.mediaStream.getAudioTracks() && this.mediaStream.getAudioTracks().length > 0
    }

    get videoTrack() {
        return this.hasVideo() ? this.mediaStream.getVideoTracks()[0] : null
    }

    get audioTrack() {
        return this.hasAudio() ? this.mediaStream.getAudioTracks()[0] : null
    }

    set videoTrack(vt) {
        if (vt != null) {
            if (this.hasVideo()) {
                this.mediaStream.removeTrack(this.mediaStream.getVideoTracks()[0])
                this.mediaStream.addTrack(vt)
            }
            vt.onended = () => {
                this.onvideoend()
            }
            vt.onstarted = () => {
                this.onvideostarted()
            }
            vt.onmute = () => {
                this.onvideomute()
            }
            vt.onunmute = () => {
                this.onvideounmute()
            }
        }
    }

    set audioTrack(at) {
        if (at != null) {
            if (this.hasAudio()) {
                this.mediaStream.removeTrack(this.mediaStream.getAudioTracks()[0])
                this.mediaStream.addTrack(at)
            }
            at.onended = () => {
                this.onaudioend()
            }
            at.onstarted = () => {
                this.onaudiostarted()
            }
            at.onmute = () => {
                this.onaudiomute()
            }
            at.onunmute = () => {
                this.onaudiounmute()
            }
        }
    }

    stop() {
        if (this.mediaStream.stop) {
            this.mediaStream.stop()
        } else {
            this.stopVideo()
            this.stopAudio()
        }
    }

    stopVideo() {
        if (this.videoTrack) {
            this.videoTrack.stop()
            this.mediaStream.removeTrack(this.videoTrack)
        }
    }

    stopAudio() {
        if (this.audioTrack) {
            this.audioTrack.stop()
            this.mediaStream.removeTrack(this.audioTrack)
        }
    }

    clone() {
        let mediaStream = new MediaStream()
        if (this.videoTrack) {
            mediaStream.addTrack(this.videoTrack.clone())
        }
        if (this.audioTrack) {
            mediaStream.addTrack(this.audioTrack.clone())
        }
        return new StreamWrapper(mediaStream)
    }

    setVideoEnabled(isEnabled) {
        if (this.videoTrack) {
            this.videoTrack.enabled = isEnabled
        }
    }

    setAudioEnabled(isEnabled) {
        if (this.audioTrack) {
            this.audioTrack.enabled = isEnabled
        }
    }

    isVideoEnabled() {
        return this.videoTrack && this.videoTrack.enabled
    }

    isAudioEnabled() {
        return this.audioTrack && this.audioTrack.enabled
    }

    // load(plugin){
    //     //todo check plugin
    //     plugin.source = this.mediaStream;
    //     return this;
    // }
}

export default StreamWrapper
