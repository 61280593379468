import { getLogger } from '../util/log'
import BrtcStreamManager from '../brtc/StreamManager'

const logger = getLogger('blive stream-manager')

export default class BLiveStreamManager extends BrtcStreamManager {
    constructor(options) {
        super(options)
    }

    _createStream(opts) {
        return  global.BLive.createStream(opts)
    }

    _createScreenStream(data) {
        let { resolve, reject,videoSource, audioSource, opts } = data
        // 对 videoSource 和 audioSource 进行处理后
        let localStream = global.BLive.createStream({
            videoSource: videoSource,
            audioSource: audioSource
        })
        logger.info('_createScreenStream', opts, (opts && opts.screenShareProfile) || '720p')
        // localStream.setScreenProfile((opts && opts.agoraDefinition) || '720p')
        localStream.init().then(
            () => {
                if (opts && opts.screenShareProfile) {
                    // BRTC 仅支持通过 setVideoEncoderConfiguration 设置屏幕分享的帧率码率，分辨率不可设置
                    localStream.setVideoEncoderConfiguration(opts.screenShareProfile) 
                }
                
                localStream.sVideoSource = videoSource
                localStream.sAudioSource = audioSource
                resolve(localStream)
            },
            (err) => reject(err)
        )
    }
}
