import MediaBlockFrameRateStrategy from './MediaBlockFrameRateStrategy'
import MediaBlockLossRateStrategy from './MediaBlockLossRateStrategy'
import { LOSS_RATE_TYPE_AUDIO, LOSS_RATE_TYPE_VIDEO } from '../config'
import MediaBlock from './MediaBlock'
export default class MediaBlockManager {
    constructor(mediaBlockConfig) {
        this.mediaBlockConfig = mediaBlockConfig
        this.upLinkMap = {}
        this.downLinkMap = {}
    }

    createMediaBlockInstance(sessionId, isPush, hasLossRate) {
        let strategyArray = []
        let blockConfig = {
            win: this.mediaBlockConfig.win / 2, // 时间间隔为2秒
            videoLossRate: this.mediaBlockConfig.videoLossRate,
            audioLossRate: this.mediaBlockConfig.audioLossRate,
            fps: isPush ? this.mediaBlockConfig.pubFps : this.mediaBlockConfig.subFps
        }
        strategyArray.push(new MediaBlockFrameRateStrategy(blockConfig))
        if (hasLossRate) {
            strategyArray.push(
                new MediaBlockLossRateStrategy(
                    { win: blockConfig.win, lossRate: blockConfig.audioLossRate },
                    LOSS_RATE_TYPE_AUDIO
                )
            )
            strategyArray.push(
                new MediaBlockLossRateStrategy(
                    { win: blockConfig.win, lossRate: blockConfig.videoLossRate },
                    LOSS_RATE_TYPE_VIDEO
                )
            )
        }
        let instance = new MediaBlock(strategyArray)
        if (isPush) {
            this.upLinkMap[sessionId] = instance
        } else {
            this.downLinkMap[sessionId] = instance
        }
        return instance
    }
}
