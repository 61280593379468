import browserUtil from './browser'

// 按颜色值，而不是按传统的日志级别，颜色参考如下
// https://v4.bootcss.com/docs/4.0/components/alerts/#examples

function toArray(array) {
    if (Array.isArray(array)) {
        return array
    }
    return Array.prototype.slice.call(array)
}

function push(array, item) {
    if (Array.isArray(item)) {
        item.forEach(function (element) {
            array.push(element)
        })
    } else {
        array.push(item)
    }
}

function output(params, color, backgroundColor) {
    var args = []
    if (!browserUtil.ie && !browserUtil.edge) {
        args.push('%c' + params[0], 'color:' + color + ';background-color:' + backgroundColor + ';padding: 2px 4px;')
    } else {
        args.push(params[0])
    }
    push(args, toArray(params).slice(1))
    try {
        // 打开了控制台日志才打印
        console.log.apply(console, args)
    } catch (err) {
        // IE 不支持这个
    }
}

export function primary() {
    output(arguments, '#004085', '#cce5ff')
}

export function secondary() {
    output(arguments, '#464a4e', '#e7e8ea')
}

export function success() {
    output(arguments, '#155724', '#d4edda')
}

export function danger() {
    output(arguments, '#721c24', '#f8d7da')
}

export function warn() {
    output(arguments, '#856404', '#fff3cd')
}

export function info() {
    output(arguments, '#0c5460', '#d1ecf1')
}

export function light() {
    output(arguments, '#818182', '#fefefe')
}

export function dark() {
    output(arguments, '#1b1e21', '#d6d8d9')
}

export function getLogger(tag) {
    function d() {
        if (!window.enableBJYDebugLog) {
            return
        }
        var argumentsArray = Array.prototype.slice.call(arguments)
        argumentsArray.unshift(tag)
        light.apply(this, argumentsArray)
    }
    function i() {
        var argumentsArray = Array.prototype.slice.call(arguments)
        argumentsArray.unshift(tag)
        info.apply(this, argumentsArray)
    }
    function w() {
        var argumentsArray = Array.prototype.slice.call(arguments)
        argumentsArray.unshift(tag)
        warn.apply(this, argumentsArray)
    }
    function e() {
        var argumentsArray = Array.prototype.slice.call(arguments)
        argumentsArray.unshift(tag)
        danger.apply(this, argumentsArray)
    }
    return {
        info: i,
        warn: w,
        error: e,
        debug: d
    }
}
