export const PLAYER_TYPE = {
    MAIN_CAMERA: 0,
    ASSIST_CAMERA: 1,
    MEDIA_FILE: 2,
    MAIN_SCREEN_SHARE: 3,
    ASSIST_SCREEN_SHARE: 4,
    ASSIST_MEDIA_FILE: 5
}

export const STREAM_TYPE = {
    AV: 'av',
    VIDEO: 'video',
    AUDIO: 'audio'
}

/**
 * 画质 - 流畅
 *
 * @type {number}
 */
export const VIDEO_QUALITY_LOW = 0

/**
 * 画质 - 标清
 *
 * @type {number}
 */
export const VIDEO_QUALITY_MEDIUM = 1

/**
 * 画质 - 高清
 *
 * @type {number}
 */
export const VIDEO_QUALITY_HIGH = 2

/**
 * 画质 - 超清
 *
 * @type {number}
 */
export const VIDEO_QUALITY_SUPER = 3

/**
 * 画质 - 720-1M
 *
 * @type {number}
 */
export const VIDEO_QUALITY_720P = 4

/**
 * 画质 - 1080-2M
 *
 * @type {number}
 */
export const VIDEO_QUALITY_1080P = 5

/**
 * 音视频卡顿阈值 默认值
 */
export const DEFAULT_MEDIA_BLOCK_CONFIG = {
    win: 10,
    videoLossRate: 20,
    audioLossRate: 30,
    pubFps: 5,
    subFps: 5
}

/**
 * 丢包类型 - 音频
 */
export const LOSS_RATE_TYPE_AUDIO = 1

/**
 * 丢包类型 - 视频
 */
export const LOSS_RATE_TYPE_VIDEO = 2
