import { PLAYER_TYPE } from '../config'
import { getPlayerIdFromSessionId, getStreamType } from '../util/util'

let clientMap = {}
let streamMap = {}

function removeStream(type, userId) {
    let array = streamMap[type]
    let index = -1
    if (array && array.length) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].userId === userId) {
                index = i
                break
            }
        }
    }
    if (index > -1) {
        array.splice(index, 1)
        return true
    }
    return false
}

export function publisherCache(type) {
    let client = clientMap[type]
    let streams = streamMap[type]
    if (streams && streams.length && client && !client.streamAddHold) {
        for (let i = 0; i < streams.length; i++) {
            client.emit('publisher', streams[i])
        }
    }
    streamMap[type] = []
}

export function getClient(sessionId) {
    return clientMap[getStreamType(sessionId)]
}

export function addClient(type, client) {
    clientMap[type] = client
}

export function removeClient(type) {
    delete clientMap[type]
}

export function emitDownloadState(sessionId, state) {
    let client = getClient(sessionId)
    if (client) {
        let userId = getPlayerIdFromSessionId(sessionId)
        client.emit('rtcat-stats', {
            userId: '' + userId,
            log: state
        })
    }
}

export function addPublisher(sessionId, stream) {
    let type = getStreamType(sessionId)
    let userId = '' + getPlayerIdFromSessionId(sessionId)
    let client = clientMap[type]

    if (client) {
        client.emit('publisher', {
            stream: stream,
            userId: userId
        })
    } else {
        if (!streamMap[type]) {
            streamMap[type] = []
        }
        streamMap[type].push({
            stream: stream,
            userId: userId
        })
    }
}

export function updatePublish(sessionId, stream) {
    let type = getStreamType(sessionId)
    let userId = '' + getPlayerIdFromSessionId(sessionId)
    let client = clientMap[type]

    if (client) {
        client.emit('updatePublisher', {
            stream: stream,
            userId: userId
        })
    } else {
        if (removeStream(type, userId)) {
            if (!streamMap[type]) {
                streamMap[type] = []
            }
            streamMap[type].push({
                stream: stream,
                userId: userId
            })
        }
    }
}

export function removePublish(sessionId, stream) {
    let type = getStreamType(sessionId)
    let userId = '' + getPlayerIdFromSessionId(sessionId)
    let client = clientMap[type]

    if (client) {
        client.emit('unpublish', {
            stream: stream,
            userId: userId
        })
    } else {
        removeStream(type, userId)
    }
}

export async function subscribe(stream) {
    let client = clientMap[PLAYER_TYPE.MAIN_CAMERA]
    if (client) {
        try {
            return await client._subscribe(stream)
        } catch (error) {
            throw error
        }
    }
}

export async function mutePeer(stream, media) {
    let client = clientMap[PLAYER_TYPE.MAIN_CAMERA]
    if (client) {
        try {
            await client._mutePeer(stream, media)
        } catch (error) {
            throw error
        }
    }
}

export async function unsubscribe(stream) {
    let client = clientMap[PLAYER_TYPE.MAIN_CAMERA]
    if (client) {
        try {
            await client._unsubscribe(stream)
        } catch (error) {
            throw error
        }
    }
}

export async function quit() {
    for (let type in clientMap) {
        await clientMap[type].destroy()
    }
}

export function flencyReport(sessionId) {
    let session = getClient(sessionId)
    session && session.emit('flency-report', { userId: getPlayerIdFromSessionId(sessionId) })
}
