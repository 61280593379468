import { STREAM_TYPE } from './config'

import AgoraStreamManager from './agora/AgoraStreamManager'
import AgoraSessionManager from './agora/AgoraSessionManager'
import TencentSessionManager from './tencent/SessionManager'
import TencentStreamManager from './tencent/StreamManager'
import BrtcStreamManager from './brtc/StreamManager'
import BrtcSessionManager from './brtc/SessionManager'
import BLiveSessionManager from './blive/SessionManager'
import BLiveStreamManager from './blive/StreamManager'

if (typeof global.AgoraRTC !== 'undefined') {
    global.AgoraRTC.Logger.enableLogUpload()
    global.AgoraRTC.Logger.setLogLevel(global.AgoraRTC.Logger.INFO)
}

typeof global.TRTC !== 'undefined' && global.TRTC.Logger.setLogLevel(global.TRTC.Logger.LogLevel.INFO)

typeof global.BRTC !== 'undefined' && global.BRTC.Logger.setLevel(global.BRTC.Logger.ERROR)

export {
    AgoraStreamManager,
    AgoraSessionManager,
    TencentSessionManager,
    TencentStreamManager,
    BrtcStreamManager,
    BrtcSessionManager,
    STREAM_TYPE,
    BLiveSessionManager,
    BLiveStreamManager
}
