/**
 * @file 等待操作
 * @author zhaogaoxing
 */

export default class Sleep {
    constructor(timeout) {
        this.timeout = timeout
    }
    then(resolve, reject) {
        const startTime = Date.now()
        this._timer = setTimeout(() => {
            resolve(Date.now() - startTime)
            this._timer = null
        }, this.timeout * 1000)
    }
    stop() {
        if (this._timer) {
            clearTimeout(this._timer)
            this._timer = null
        }
    }
}
