import Events from 'events'

class StreamManager extends Events {
    constructor() {
        super()
        this.fps = 20
        this.size = {
            width: 320,
            height: 240
        }
        this.audioContext = null
        try {
            if ('audioContext' in RTCat) {
                this.audioContext = RTCat.audioContext
            } else {
                this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
            }
        } catch (e) {}
        this.gainSupported = !!this.audioContext
    }

    getGainSupported() {
        return this.gainSupported
    }

    scanCamera() {
        return global.RTCat.scanCamera()
    }

    getCameraDevices() {
        return global.RTCat.getCameraDevices()
    }

    getAudioInputDevices() {
        return global.RTCat.getAudioInputDevices()
    }

    getAudioOutputDevices() {
        return global.RTCat.getAudioOutputDevices()
    }

    createAVStream() {}

    createScreenStream() {}

    resumeAudioContext() {
        return this.audioContext.resume()
    }
}

export default StreamManager
