/**
 * @file 订阅session的管理。所有流的订阅,都通过主摄像头的session
 * @auth yanlingling
 */
import MainSession from './MainSession'
import { getLogger } from '../util/log'

let mainInstance = null
let mainInstanceCreatedSuccess = false
let streamTypeExisted = {}
let connectingPromise
const logger = getLogger('agroa main-session-manager')

const manager = {
    /**
     * 创建拉流session单例
     */
    create: function ({
        mode = 'live',
        codec = 'h264',
        appId,
        token,
        channel,
        playerId,
        userId,
        streamType,
        webrtcInfo,
        lags
    } = {}) {
        addStreamType(streamType)

        function createMainInstance() {
            return new Promise((resolve, reject) => {
                mainInstance = new MainSession()
                mainInstance
                    .createSession({
                        mode,
                        codec,
                        appId,
                        token,
                        channel,
                        playerId,
                        userId,
                        streamType,
                        webrtcInfo,
                        lags
                    })
                    .then(() => {
                        mainInstanceCreatedSuccess = true
                        mainInstance.sendPublishedQueue(streamType)
                        resolve(mainInstance)
                    })
                    .catch((reason) => {
                        reject(reason)
                    })
            })
        }

        if (mainInstance) {
            logger.info('main session has inited: ', mainInstance.sessionId)

            // userId 变了,说明断网了
            if (parseInt(userId) !== mainInstance.sessionId) {
                logger.info('network disconnect, recreate mainInstance')
                mainInstance && manager.destroy()
                connectingPromise = createMainInstance()
            } else {
                if (mainInstanceCreatedSuccess) {
                    setTimeout(function () {
                        mainInstance.sendPublishedQueue(streamType)
                    }, 0)
                }
            }
        } else {
            logger.info('try to init main session')
            connectingPromise = createMainInstance()
        }

        return connectingPromise
    },

    /**
     * 删除某种拉流的类型
     */
    removeStreamType: function (streamType) {
        delete streamTypeExisted[streamType]
    },

    /**
     * 获取当前有多少种拉流类型有效
     */
    getStreamTypes: function () {
        return Object.keys(streamTypeExisted)
    },

    /**
     * 销毁
     */
    destroy: function () {
        logger.info('destroy main session manager: ', mainInstance && mainInstance.sessionId)

        return new Promise((resolve, reject) => {
            if (mainInstance) {
                mainInstance._destroySession().then(
                    () => {
                        resolve()
                    },
                    (error) => {
                        reject(error)
                    }
                )
                mainInstance.removeAllListeners()
                mainInstance = null
                mainInstanceCreatedSuccess = false
            } else {
                resolve()
            }
        })
    }
}

let addStreamType = function (streamType) {
    if (streamTypeExisted[streamType]) {
        return
    }
    streamTypeExisted[streamType] = true
}

export default manager
