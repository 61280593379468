/**
 * 音视频卡顿
 */
export default class MediaBlock {
    /**
     * @param {*} strategyArray 卡顿
     */
    constructor(strategyArray) {
        this.strategyArray = strategyArray
        return this
    }

    _setMediaState(state) {
        if (this.strategyArray && Array.isArray(this.strategyArray)) {
            this.strategyArray.forEach((strategy) => {
                strategy && strategy.setMediaState && strategy.setMediaState(state)
            })
        }
    }

    _checkBlockStatus(state) {
        let result = false
        for (let index in this.strategyArray) {
            let strategy = this.strategyArray[index]
            if (!result) {
                result = strategy && strategy.checkBlockStatus && strategy.checkBlockStatus(state)
            }
        }
        return result
    }

    /**
     * 是否卡顿判断
     * @param {Object} state
     * @param {Number?} state.audioLossRate 音频丢包率
     * @param {Number?} state.videoLossRate 视频丢包率
     * @param {Number} state.fps 帧率
     */
    isBlock(state) {
        this._setMediaState(state)
        return this._checkBlockStatus(state)
    }

    getValues() {
        let result = {}
        for (let index in this.strategyArray) {
            let strategy = this.strategyArray[index]
            Object.assign(result, {
                [[strategy.getName()]]: strategy.getValue()
            })
            if (strategy.checkBlockStatus()) {
                Object.assign(result, {
                    [[strategy.getName() + '_Blocked']]: strategy.checkBlockStatus()
                })
            }
        }
        return result
    }
}
